<template>
    <div>
        <v-col cols="12">
            <div class="marketing-container">
                <iframe :src="'https://keap.page/rje671/leads.html'" frameborder="0" class="marketing-iframe"></iframe>
            </div>
        </v-col>
    </div>
</template>
<script>
    export default {
        name: 'Marketing',
        props: []
    };
</script>
